import { tv } from 'tailwind-variants'

export const simpleTableVariant = tv({
  slots: {
    table: 'group/table w-full',
    thead: 'group/thead',
    theadTr: 'group/tr text-left bg-gray-850 text-gray-200',
    theadTh: 'group/th',
    tbody: 'group/tbody',
    tbodyTr: 'group/tr text-left bg-gray-850 text-gray-200',
    tbodyTd: 'group/td',
    tfoot: 'group/tfoot',
    tfootTr: 'group/tr text-left bg-gray-900 text-gray-200',
    tfootTh: 'group/th',
  },
  variants: {
    orientation: {
      horizontal: undefined,
      vertical: undefined,
    },
    hoverable: {
      true: {
        tbodyTr: 'hover:bg-gray-800',
      },
    },
    clickable: {
      true: undefined,
    },
    dividedRows: {
      true: {
        thead: 'border-b border-b-gray-800',
        tbody: 'divide-y divide-gray-800',
        tfoot: 'border-t border-t-gray-800',
      },
    },
    stripedRows: {
      true: {
        tbodyTr: 'odd:bg-gray-850 even:bg-gray-800',
      },
    },
    showGridlines: {
      true: {
        table: 'border-separate border-spacing-0',
        theadTh: 'border-y border-r border-gray-750 first:border-l',
        tbodyTd: 'border-b border-r border-gray-750 first:border-l',
        tfootTh: 'border-y border-r border-gray-850 first:border-l',
      },
    },
    fontSize: {
      sm: {
        theadTh: 'text-sm',
        tbodyTd: 'text-sm',
        tfootTh: 'text-sm',
      },
      md: {
        theadTh: 'text-base',
        tbodyTd: 'text-base',
        tfootTh: 'text-base',
      },
      lg: {
        theadTh: 'text-lg',
        tbodyTd: 'text-lg',
        tfootTh: 'text-lg',
      },
      xl: {
        theadTh: 'text-xl',
        tbodyTd: 'text-xl',
        tfootTh: 'text-xl',
      },
      base: {
        theadTh: 'text-base',
        tbodyTd: 'text-base',
        tfootTh: 'text-base',
      },
    },
    padding: {
      sm: {
        theadTh: 'px-2 py-1',
        tbodyTd: 'px-2 py-1',
        tfootTh: 'px-2 py-1',
      },
      md: {
        theadTh: 'px-4 py-2.5',
        tbodyTd: 'px-4 py-2.5',
        tfootTh: 'px-4 py-2.5',
      },
      lg: {
        theadTh: 'px-6 py-3.5',
        tbodyTd: 'px-6 py-3.5',
        tfootTh: 'px-6 py-3.5',
      },
      none: undefined,
    },
    rounded: {
      true: {
        table: 'rounded-lg',
      },
      false: undefined,
    },
    sticky: {
      true: undefined,
      false: undefined,
    },
  },
  compoundVariants: [
    // Hoverable
    // TODO: Vertical hover
    {
      hoverable: true,
      stripedRows: true,
      class: {
        tbodyTr: 'odd:hover:bg-gray-750 even:hover:bg-gray-750',
      },
    },
    // Rounded
    {
      rounded: true,
      orientation: 'horizontal',
      class: {
        table: [
          '[&>tr:first-child>th:first-child]:rounded-tl-lg',
          '[&>tr:first-child>th:last-child]:rounded-tr-lg',
          '[&>tr:last-child>td:first-child]:rounded-bl-lg',
          '[&>tr:last-child>td:last-child]:rounded-br-lg',
        ],
      },
    },
    {
      rounded: true,
      orientation: 'vertical',
      class: {
        table: [
          '[&>tr:first-child>th]:rounded-tl-lg',
          '[&>tr:first-child>td]:rounded-tr-lg',
          '[&>tr:last-child>th]:rounded-bl-lg',
          '[&>tr:last-child>td]:rounded-br-lg',
        ],
      },
    },
    // Clickable
    {
      clickable: true,
      orientation: 'horizontal',
      class: {
        tbodyTr: 'cursor-pointer',
      },
    },
    // Sticky
    {
      sticky: true,
      orientation: 'horizontal',
      class: {
        thead: 'sticky top-0 z-10',
        tfoot: 'sticky bottom-0 z-10',
      },
    },
    {
      sticky: true,
      orientation: 'vertical',
      class: {
        theadTh: 'sticky left-0 z-10 bg-[inherit]',
        tfootTh: 'sticky right-0 z-10 bg-[inherit]',
      },
    },
  ],
  defaultVariants: {
    orientation: 'horizontal',
    hoverable: true,
    clickable: false,
    rounded: false,
    dividedRows: false,
    stripedRows: false,
    showGridlines: false,
    padding: 'md',
    fontSize: 'base',
    sticky: false,
  },
})

export type SimpleTableVariant = ReturnType<typeof simpleTableVariant>
